import supabase from './supabaseClient.js';

export const fetchQuestionData = async (school, codeWord) => {
  const { data, error } = await supabase
    .rpc('fetch_question_data', { p_school: school, p_code_word: codeWord })
    .single();

  if (error) {
    console.error('Error fetching question data:', error);
    return { question: '', exampleEssays: [] };
  }

  return {
    question: data.question,
    exampleEssays: data.example_essays,
  };
};